<template>
<v-container fluid v-if="show">

    <h1> Бонусні програми</h1>
    <v-card flat tile width="100%" class="mt-2" v-if="$vuetify.breakpoint.smAndDown">
        <v-btn color="primary" block dark @click.stop="drawerOpenClose()">
            Фільтри
            <v-icon right>mdi-filter-menu-outline</v-icon>
        </v-btn>
        <v-navigation-drawer v-model="$store.state.bonus.filtersDrawerOpened" stateless clipped temporary app width="320">
            <BonusFiltersBlock collapsed />
        </v-navigation-drawer>
    </v-card>
    <v-row no-gutters>
        <v-col xl="2" lg="2" md="3" class="pr-2" v-if="$vuetify.breakpoint.mdAndUp">
            <BonusFiltersBlock />
        </v-col>
        <v-col xl="10" lg="10" md="9">
            <v-toolbar flat>
                <v-text-field light @change="loadListR()" class="ml-n4 mr-1 mt-3 mt-md-n7" v-model="bonusId" flat clearable outlined solo dense hide-details append-icon="search" label="Шукати за номером">
                </v-text-field>
                <v-spacer></v-spacer>
                <v-text-field light @change="loadListR()" class="mr-n4 ml-1 mt-3 mt-md-n7" v-model="typhoonDoc" flat clearable outlined solo dense hide-details append-icon="search" label="Шукати за документом нарахування">
                </v-text-field>
            </v-toolbar>
            <v-alert type="info" dense border="left" colored-border elevation="2" class="black--text caption mt-4 mt-md-0">Зверніть увагу! Нарахування бонусів відбувається за умови виконання термінів по ДЗ, а саме:
                Середньодобова протермінована заборгованість не повинна перевищувати 10% загального обороту за місяць на період дії умов бонусної програми.
            </v-alert>
            <div class="body-2 text-right px-0 px-md-2 mt-4">
                Знайдено програм: {{totalItems}}</div>
            <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="confirmedFalse">
                Немає доступу до інформації. Ваші контактні дані не підтверджені менеджером.
            </v-alert>
            <v-alert max-width="700" type="error" outlined class="my-6 mx-auto" v-if="contractorsFalse">
                Немає жодного доступного контрагента для перегляду бонусних програм.
            </v-alert>
            <v-data-table v-if="!confirmedFalse" class="bonus" @click:row="clicked" item-key="id" show-expand :expanded.sync="expanded" :loading="listLoading" :items="list" hide-default-footer :page.sync="page" :headers="filteredHeaders" @page-count="pageCount = $event" :server-items-length="totalItems" :options.sync="options" mobile-breakpoint="0">
                <template v-slot:item.bonus_id="{ item }">
                    <div :id="`to_${item.id}`">{{item.bonus_id}}</div>
                </template>
                <template v-slot:item.period="{ item }">
                    <div class="d-inline-block">
                        <div>{{item.period_from}}<span class="d-none d-lg-inline"> - </span><span class="d-block d-lg-none"> </span>{{item.period_to}}</div>
                        <div class="d-flex justify-content-between green--text caption">
                            <v-progress-linear :value="item.progress" color="green" rounded class="align-self-center mr-1 mr-md-2"></v-progress-linear>
                            {{item.progress}}%
                        </div>
                    </div>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-chip :class="(statuses[item.status].color || `grey`) + ` d-block white--text font-weight-bold`">{{statuses[item.status].name}}</v-chip>
                </template>
                <template v-slot:item.category="{ item }">
                    {{ item.assort_type == 'all' ? 'Всі закупки' : item.category }}
                </template>

                <template v-slot:item.bonus="{ item }">
                    <div v-if="bonusData[item.bonus_id] && bonusCalcData[item.id]">
                        {{bonusData[item.bonus_id].bonus_usd[item.id] > 0 ? format(bonusData[item.bonus_id].bonus_usd[item.id]) : format(bonusData[item.bonus_id].bonus_uah[item.id])}}
                        {{toCur(bonusCalcData[item.id][0].range_loop[0].basis_unit)}}
                    </div>
                    <v-progress-linear v-if="!bonusData[item.bonus_id]" color="red lighten-2" buffer-value="0" stream></v-progress-linear>
                </template>
                <template v-slot:item.targetMax="{ item }">
                    <div v-if="bonusCalcData[item.id] && bonusCalcData[item.id].length == 1">
                        {{targetMaxValue(item)}}
                        <div class="d-flex justify-content-between green--text caption">
                            <v-progress-linear :value="targetMax(item)" color="green" rounded class="align-self-center mr-2"></v-progress-linear>
                            {{targetMax(item)}}%
                        </div>
                    </div>
                    <div v-if="bonusCalcData[item.id] && bonusCalcData[item.id].length > 1">
                        див. детальніше
                    </div>
                </template>
                <template v-slot:item.bonus_max="{ item }">
                    <div v-if="bonusCalcData[item.id] && bonusCalcData[item.id][0]" class="text-no-wrap">
                        {{bonusMax(item.id)}} {{toCur(bonusCalcData[item.id][0].range_loop[0].basis_unit)}}
                    </div>
                </template>

                <template v-slot:loading>
                    <div class="py-10 body-2 grey--text text-center font-weight-light">Зачекайте...</div>
                </template>
                <template v-slot:no-data>
                    <div class="py-10 body-2 grey--text text-center font-weight-light">Немає жодної бонусної програми</div>
                </template>

                <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
                    <v-btn v-if="bonusCalcData[item.id] && bonusCalcData[item.id][0] && bonusCalcData[item.id][0].range_loop" :small="$vuetify.breakpoint.mdAndUp ? true : false" :x-small="$vuetify.breakpoint.smAndDown ? true : false" :class="`ma-0 ml-n2 `+ (statuses[item.status].color || `grey`)" dark icon>
                        <v-icon>{{isExpanded ? 'mdi-chevron-up':'mdi-chevron-down'}}</v-icon>
                    </v-btn>
                    <v-progress-circular v-if="!bonusCalcData[item.id]" indeterminate color="red lighten-4"></v-progress-circular>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-4 pb-8" v-if="bonusData[item.bonus_id] ">
                        <v-card tale flat>
                            <!-- <v-card-title class="pl-1">
                                Інформація по програмі
                            </v-card-title> -->
                            <div><b>Контрагент:</b> {{bonusData[item.bonus_id].contractor_view}}</div>
                            <div v-if="bonusData[item.bonus_id].for_holding == 1"><b>Умови діють на корпорацію:</b> {{ bonusData[item.bonus_id].holding_contractors}}</div>
                            <div v-if="bonusData[item.bonus_id].all_calcobj_required == 1"><b>Додаткові умови нарахування бонуса:</b> Обов'язкове виконання всіх встановлених умов розрахунку у програмі</div>
                            <div v-if="bonusData[item.bonus_id].bonus_type_view"><b>Примітки до бонусу:</b> {{bonusData[item.bonus_id].bonus_type_view}}</div>
                            <div v-if="bonusCalcData[item.id]" class="mt-4">
                                Бонус:
                                <span v-if="bonusData[item.bonus_id].bonus_uah[item.id] > 0" class="green--text darken-2 font-weight-bold title">{{format(bonusData[item.bonus_id].bonus_uah[item.id])}}<span class="caption">₴</span></span>
                                <span v-if="bonusData[item.bonus_id].bonus_usd[item.id] > 0" class="green--text darken-2 font-weight-bold title">{{format(bonusData[item.bonus_id].bonus_usd[item.id])}}<span class="caption">$</span></span>
                                <span v-if="bonusData[item.bonus_id].bonus_usd[item.id] == 0 && bonusData[item.bonus_id].bonus_uah[item.id] == 0" class="red--text darken-2 font-weight-bold title">0<span class="caption">{{toCur(bonusCalcData[item.id][0].range_loop[0].basis_unit)}}</span></span>
                            </div>
                            <div v-if="bonusCalcData[item.id] && bonusCalcData[item.id][0] && bonusCalcData[item.id][0].range_loop && bonusCalcData[item.id][0].range_loop[0] && bonusCalcData[item.id][0].range_loop[0].typhoon_docs">
                                Документ нарахування: {{bonusCalcData[item.id][0].range_loop[0].typhoon_docs}}
                            </div>
                        </v-card>
                        <v-row v-for="(scale, key) in bonusCalcData[item.id]" v-if="bonusCalcData[item.id]" :key="key">
                            <v-col cols="12" md="4">
                                <v-list-group no-action v-model="assort" :value="$vuetify.breakpoint.smAndUp ? 1 : 0" class="desc" active-class="second--text">
                                    <v-list-item-title slot="activator" class="caption font-weight-bold ml-n2">
                                        Треба закупити товари
                                    </v-list-item-title>
                                    <v-divider class="grey lighten-2"></v-divider>
                                    <div v-for="(item, key) in scale.range_loop" :key="key" class="mb-2 ml-2">
                                        <div v-if="item.range_rowspan && key == 0" class="pt-2 second--text">
                                            <div v-if="item.assort_type_all == 1">Всі закупки</div>
                                            <div v-else>
                                                <div class="font-weight-bold" v-for="fl in item.category_brand.first_level" :key="fl">
                                                    {{fl}}
                                                    <div class="pl-10 py-0 font-weight-regular" v-for="el in item.category_brand.data[fl]" :key="fl.lname">
                                                        {{el.lname || el.altname}} <span v-if="el.brand" class="font-weight-light"> / {{el.brand}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="item.product_ex_view"><br><i>Окрім товарів:</i>
                                                <div v-html="item.product_ex_view"></div>
                                            </div>
                                            <div v-if="item.product_view"><br><span class="text-decoration-underline">Додатково товари:</span><br>
                                                <div v-html="item.product_view"></div>
                                            </div>
                                            <div v-if="scale.exceptions && scale.exceptions.first_level && scale.exceptions.first_level[0]" class="caption font-italic mt-2">
                                                <span class="red--text">Зараховуються до виконання плану, але виключаються з нарахування бонусів:</span>
                                                <div class="font-weight-bold" v-for="fl in scale.exceptions.first_level" :key="fl">
                                                    {{fl}}
                                                    <div class="pl-10 font-weight-regular" v-for="el in scale.exceptions.data[fl]" :key="el.lname">
                                                        {{el.lname || el.altname}} <span v-if="el.brand" class="font-weight-light"> / {{el.brand}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-list-group>
                                <v-divider class="grey lighten-2"></v-divider>
                                <div v-if="!assort" class="font-italic grey--text lighten-2 py-4 text-center">розкрити перелік товарів</div>
                            </v-col>
                            <v-col cols="12" md="8">
                                <v-simple-table class="desc">
                                    <tr>
                                        <th width="40%">На суму</th>
                                        <th width="20%">Розмір бонусу</th>
                                        <th class="text-center" width="20">Факт</th>
                                        <th class="text-center" width="20%">Виконання</th>
                                    </tr>
                                    <tr v-for="(item, key) in scale.range_loop" :key="key">
                                        <td :class="item.range_fullfilled == 1 ? `green--text px-2`: `px-2`">
                                            <div v-if="item.multiplicity==1">кратно {{format(item.divider)}} {{toCur(item.basis_unit)}}</div>
                                            <div v-else>
                                                <span v-if="item.low_undef == 1">менш ніж </span>
                                                <span v-if="item.low != null">від {{format(item.low)}} {{toCur(item.basis_unit)}} </span>

                                                <span v-if="item.high_undef == 1 || item.high == 0">та більше </span>
                                                <span v-if="item.high != null && item.high > 0">до {{format(item.high)}} {{toCur(item.basis_unit)}}</span>
                                            </div>
                                        </td>
                                        <td :class="item.range_fullfilled == 1 ? `green--text text-center`: `text-center`">
                                            {{item.result}} {{toCur(item.form_unit)}}
                                        </td>
                                        <td :rowspan="item.range_rowspan" v-if="key == 0" class="text-center">{{format(item.overturn)}} {{toCur(item.overturn_unit)}}</td>
                                        <td class="text-center">
                                            <span v-if="item.range_fullfilled == 1">
                                                <v-icon color="green">mdi-check</v-icon>
                                            </span>
                                            <span v-if="item.range_fullfilled != 1 && !item.next_target">-</span>
                                            <span v-if="item.range_fullfilled != 1 && item.next_target" class="red--text">- {{format(item.needed_overturn_delta)}} {{toCur(item.overturn_unit)}}</span>
                                        </td>
                                    </tr>
                                </v-simple-table>
                                <!-- <div v-if="bonusCalcData[item.id] && bonusCalcData[item.id][0] && bonusCalcData[item.id][0].range_loop && bonusCalcData[item.id][0].range_loop[0] && bonusCalcData[item.id][0].range_loop[0].next_bonus_uah">від {{format(bonusCalcData[item.id][0].range_loop[0].next_bonus_uah)}} {{toCur(bonusCalcData[item.id][0].range_loop[0].overturn_unit)}}</div> -->
                            </v-col>
                        </v-row>
                    </td>
                </template>

            </v-data-table>
            <div class="text-xs-center pt-6">
                <v-pagination @input="paginator" color="primary" v-model="page" :length="pageCount" :total-visible="totalPagesPagination"></v-pagination>
            </div>
            <AlertCompanyBlock />
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    mapActions,
} from 'vuex'
import AlertCompanyBlock from "./AlertCompanyBlock.vue"
import BonusFiltersBlock from './BonusFiltersBlock.vue'
export default {
    components: {
        AlertCompanyBlock,
        BonusFiltersBlock
    },
    props: {},
    data() {
        return {
            page: 1,
            bonusCalcData: {},
            bonusData: {},
            bonusId: '',
            typhoonDoc: '',
            show: false,
            confirmedFalse: false,
            contractorsFalse: false,
            data: '',
            pageCount: 0,
            totalPagesPagination: 7,
            options: {
                page: 1,
                itemsPerPage: 20
            },
            expanded: [],
            assort: false,

            newQuery: {},
            headers: [{
                    text: 'ID',
                    static: true,
                    align: 'center',
                    value: 'id',
                    sortable: false,
                    width: '15%',
                    hidden: true,
                },
                {
                    text: 'Номер',
                    static: true,
                    align: 'left',
                    value: 'bonus_id',
                    sortable: false,
                    width: '5%'
                },
                {
                    text: 'Період',
                    static: true,
                    align: 'center',
                    value: 'period',
                    sortable: false,
                    width: '30%'

                },
                {
                    text: 'Статус',
                    static: true,
                    align: 'center',
                    value: 'status',
                    sortable: false,
                    width: '15%',
                    hiddenXs: true
                },
                {
                    text: 'Категорія',
                    static: true,
                    align: 'center',
                    value: 'category',
                    sortable: false,
                    width: '20%'

                },
                {
                    text: 'Виконання макс. таргету',
                    static: true,
                    align: 'center',
                    value: 'targetMax',
                    sortable: false,
                    width: '15%',
                    hiddenXs: true
                },
                {
                    text: 'Поточний бонус',
                    static: true,
                    align: 'center',
                    value: 'bonus',
                    sortable: false,
                    width: '25%'

                },
                {
                    text: 'Макс. бонус',
                    static: true,
                    align: 'center',
                    value: 'bonus_max',
                    sortable: false,
                    width: '25%',
                    hiddenXs: true
                },
            ],
            statuses: {
                'current': {
                    name: 'Актуальна',
                    color: 'amber lighten-1',
                },
                'completed': {
                    name: 'Виконана',
                    color: 'light-green',
                },
                'uncompleted': {
                    name: 'Не виконана',
                    color: 'grey lighten-1',
                },
            },
        }
    },
    methods: {
        ...mapActions(['touch', 'getBonusData', 'getBonusList', 'getBonusFilters', 'getContactInfo', 'getBonusContractors']),
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        getAllSingleBonus(data) {
            for (let item of data) {
                this.getSingleBonus(item.bonus_id, item.id)
            }
        },
        getSingleBonus(bonus_id, id) {
            if (!this.bonusCalcData[id]) {
                const token = this.getLocalStorage('authToken')
                this.getBonusData({
                        token,
                        bonus_id
                    })
                    .then(data => {
                        if (data.content) {
                            this.$set(this.bonusData, bonus_id, data.content)
                        } else {
                            this.$set(this.bonusData, bonus_id, {})
                        }
                        if (data.content && data.content.calc && data.content.calc[id]) {
                            this.$set(this.bonusCalcData, id, data.content.calc[id])
                            // if (data.content.calc[id].exceptions && data.content.calc[id].exceptions[0]) {
                            //     // console.log(bonus_id)
                            // }
                        } else {
                            this.$set(this.bonusCalcData, id, {})
                        }
                    })
                    .catch(error => console.log(error))
            }
        },
        clicked(value) {
            const exp = this.expanded[0]
            this.expanded = []
            if (value.id !== (exp ? exp.id : 0)) {
                this.expanded.push(value)
                setTimeout(() => {
                    this.$vuetify.goTo(`#to_${value.id}`, {
                        offset: 20
                    })
                }, 100);

            }
        },
        setQuery() {
            delete this.$route.query['bonusId']
            delete this.$route.query['typhoonDoc']
            Object.assign(this.newQuery, {
                ...this.$route.query,
                bonusId: this.bonusId || undefined,
                typhoonDoc: this.typhoonDoc || undefined
            })
            this.$router.push({
                path: '',
                query: this.newQuery
            })
            this.newQuery = {}
        },
        parseQuerySet() {
            const q = this.$route.query
            this.typhoonDoc = q.typhoonDoc
            this.bonusId = q.bonusId
        },
        loadData() {
            this.touch()
                .then(() => {
                    this.getBonusFilters({
                        status: this.$route.query.status,
                        bonusId: this.$route.query.bonusId,
                        typhoonDoc: this.$route.query.typhoonDoc,
                        cats: this.$route.query.cats,
                        contractors: this.$route.query.contractors
                    })
                    this.getBonusList({
                            page: this.page,
                            rowsPerPage: this.options.itemsPerPage,
                            status: this.$route.query.status,
                            bonusId: this.$route.query.bonusId,
                            typhoonDoc: this.$route.query.typhoonDoc,
                            cats: this.$route.query.cats,
                            contractors: this.$route.query.contractors
                        })
                        .then(data => {
                            this.getAllSingleBonus(data)
                        })
                })
        },
        loadListR() {
            this.setQuery()
        },
        paginator(v) {
            this.page = v
            this.loadData()
        },
        bonusMax(id) {
            const calc = this.bonusCalcData[id] && this.bonusCalcData[id][0]
            const lastR = calc.range_loop[calc.range_loop.length - 1]
            if (lastR.form == 'pct') {
                return 'від ' + this.format((lastR.high > lastR.low ? lastR.high : lastR.low) / 100 * lastR.result)
            } else {
                return this.format(lastR.form == 'usd' ? lastR.bonus_usd : lastR.bonus_uah)
            }
        },
        targetMax(item) {
            const {
                id,
            } = item
            const range = this.bonusCalcData[id] && this.bonusCalcData[id][0].range_loop && this.bonusCalcData[id][0].range_loop[0]
            const defRange = range.range_fullfilled == 1 ? 100 : 10
            if (range) {
                if (range.has_max == 1) {
                    const max_complete = Number(range.max_complete)
                    return max_complete < 0 ? 0 : ((max_complete > 300 ? 100 : Math.round(max_complete)) || defRange)
                } else {
                    const min_complete = Number(range.min_complete)
                    return min_complete <= 0 ? 0 : ((min_complete > 300 ? 100 : Math.round(min_complete)) || defRange)
                }
            } else {
                return defRange
            }
        },
        targetMaxValue(item) {
            const {
                id,
            } = item
            const range = this.bonusCalcData[id] && this.bonusCalcData[id][0].range_loop && this.bonusCalcData[id][0].range_loop[this.bonusCalcData[id][0].range_loop.length - 1]
            return `${this.format(range.low)} ${this.toCur(range.basis_unit)}`
        },
        toCur(v) {
            const cur = {
                'грн.': '₴',
                'у.е.': "$"
            }
            if (cur[v]) {
                return cur[v]
            } else {
                return v
            }
        },
        format(v) {
            if (v) {
                return Number(String(v).replace(/\s+/g, '')).toLocaleString('ru-RU')
            } else {
                return 0
            }
        },
        toNumber(v) {
            return Number(String(v).replace(/\s+/g, ''))
        },
        drawerOpenClose() {
            this.$store.commit('openCloseBonusFiltersDrawer', !this.filtersDrawerOpened);
        },
    },
    watch: {
        '$route.query'(v) {
            this.page = 1
            this.loadData()
        },
    },
    created() {
        this.getContactInfo()
            .then(data => {
                if (data.groups && data.groups.cl_corp_bonus && !data.isSellToEmployee) {
                    this.$vuetify.goTo(0)
                    this.show = true
                    if (data.isConfirmed) {
                        this.getBonusContractors()
                            .then(data => {
                                if (data[0]) {
                                    this.parseQuerySet()
                                } else {
                                    this.contractorsFalse = true
                                }
                            })
                    } else {
                        this.confirmedFalse = true
                    }
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
    computed: {
        totalItems() {
            return this.$store.state.bonus.total
        },
        list() {
            return this.$store.state.bonus.list
        },
        listLoading() {
            return this.$store.state.bonus.loading
        },
        filteredHeaders() {
            return this.headers.filter(h => {
                return !h.hidden ? (this.$vuetify.breakpoint.xs ? !h.hiddenXs : (this.$vuetify.breakpoint.smAndDown ? !h.hiddenSm : true)) : false
            })
        },

    }
}
</script>

<style>
.v-data-table.desc tr,
.v-data-table.desc tr:hover {
    background: #E3F2FD !important;
}

@media (max-width: 760px) {

    .v-data-table.bonus>.v-data-table__wrapper th,
    .v-data-table.bonus>.v-data-table__wrapper td {
        padding: 0 5px 0 0px;
    }

    .v-data-table.bonus>.v-data-table__wrapper th:nth-child(1),
    .v-data-table.bonus>.v-data-table__wrapper td:nth-child(1) {
        padding-left: 10px;
        padding-right: 0;
    }
}

@media (max-width: 512px) {

    .v-data-table.bonus>.v-data-table__wrapper th,
    .v-data-table.bonus>.v-data-table__wrapper tr:not(.v-data-table__expanded__content) td {
        font-size: 12px;
    }

    .v-data-table.bonus>.v-data-table__wrapper td {
        padding-left: 5px;
    }
}
</style>
